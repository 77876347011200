const data = [
  {
    id: 1,
    name: "c-u international school",
    stack: "Front-end",
    description: "A language school where client can learn English/Spanish.",
    tech: "REACT/TYPESCRIPT",
    img: "./profilePic/cuschool.png",
    link: "https://github.com/kabornblack/C-U-Language-School",
    isLive: true,
    liveLink: "https://www.cuilschool.ee/",
  },
  {
    id: 2,
    name: "swapify",
    stack: "Front-end",
    description: "A barter and donation platform",
    tech: "REACT/TYPESCRIPT",
    img: "./profilePic/swapify.png",
    link: "https://github.com/swapify-ou",
    isLive: true,
    liveLink: "https://www.swapify.ee",
  },
  {
    id: 3,
    name: "Booking-Clone",
    stack: "Full-Stack",
    description: "Bookinbg.com user experience.",
    tech: "REACT/TYPESCRIPT/NEXTJS",
    img: "./profilePic/booking.png",
    link: "https://github.com/kabornblack/booking-clone",
    isLive: true,
    liveLink: "https://booking-clone-tau.vercel.app/",
  },
  {
    id: 4,
    name: "Walmart-Clone",
    stack: "Full-Stack",
    description: "Walmart's online shopping experience.",
    tech: "REACT/TYPESCRIPT/NEXTJS",
    img: "./profilePic/walmart.png",
    link: "https://github.com/kabornblack/walmart-clone",
    isLive: true,
    liveLink: "https://walmart-clone-one-steel.vercel.app/",
  },
  {
    id: 5,
    name: "Disney-Clone",
    stack: "Full-Stack",
    description:
      "A clone of the Disney website strickly for personal learning purpose",
    tech: "REACT/TYPESCRIPT/NEXTJS",
    img: "./profilePic/disneyclone.png",
    link: "https://github.com/kabornblack/Disney-clone",
    isLive: true,
    liveLink: "https://disney-clone-omega-henna.vercel.app/",
  },
  {
    id: 6,
    name: "secrets",
    stack: "Back-end",
    description: "App where logged-in users can post secret anonymously.",
    tech: "NODEJS/EXPRESS/PASSPORT/MONGODB",
    //EJS
    img: "./profilePic/secrets.png",
    link: "https://github.com/kabornblack/Secrets",
    isLive: false,
  },
  {
    id: 7,
    name: "upto-date",
    stack: "Full-Stack",
    description:
      "App where you can share facts and other users can agree or disagree.",
    tech: "HTML/CSS/JAVASCRIPT/REACT",
    img: "./profilePic/uptodate.png",
    link: "https://github.com/kabornblack/Upto-date",
    isLive: true,
    liveLink: "https://uptodate-kabbi.netlify.app/",
  },
  {
    di: 8,
    name: "keeper app",
    stack: "Full-Stack",
    description: "Inspired by google keep",
    tech: "REACT/HTML/CSS",
    img: "./profilePic/keeper.png",
    link: "https://github.com/kabornblack/keeper-project",
    isLive: false,
  },
];

export default data;
